import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentPage } from '@content/content.page';
import { RouteResolver } from '@core/resolvers';
import { NotFoundPage } from '@error/not-found.page';
import { HomePage } from '@home/home.page';
import { TagComponent } from './tag/tag.component';

export const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  {
    path: 'enlaces/:etiqueta',
    component: TagComponent,
  },
  {
    path: '404',
    component: NotFoundPage,
  },
  {
    path: '**',
    resolve: { pageData: RouteResolver },
    component: ContentPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutes {}
