<div class="template--programa">
  <div class="programa__tabset">
    <nz-tabset>
      <nz-tab
        [nzTitle]="informacion.pestana.nombre"
        *ngFor="let informacion of programa?.informacion"
      >
        <article [innerHTML]="informacion.contenido"></article>
      </nz-tab>

      <nz-tab nzTitle="Docentes" *ngIf="hasDocentes">
        <div class="card-container">
          <nz-card *ngFor="let docente of programa?.docentes">
            <nz-card-meta
              [nzTitle]="docente.nombres_completos"
              [nzDescription]="tplDescription"
            >
            </nz-card-meta>
            <ng-template #tplDescription>
              <a
                [href]="docente.enlace_cv"
                nz-button
                nzType="link"
                target="_blank"
                >Ver Hoja de Vida</a
              >
            </ng-template>
          </nz-card>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>

  <div class="programa__general-info">
    <dl
      class="
        programa__general-info__detail programa__general-info__detail--semestres
      "
    >
      <dt class="programa__general-info__detail__title">Duración</dt>
      <dd class="programa__general-info__detail__description">
        {{ programa?.semestres }} semestres académicos
      </dd>
    </dl>

    <dl
      class="
        programa__general-info__detail programa__general-info__detail--creditos
      "
    >
      <dt class="programa__general-info__detail__title">Total de créditos</dt>
      <dd class="programa__general-info__detail__description">
        {{ programa?.creditos }} créditos
      </dd>
    </dl>

    <dl
      class="
        programa__general-info__detail programa__general-info__detail--horario
      "
    >
      <dt class="programa__general-info__detail__title">Horario</dt>
      <dd class="programa__general-info__detail__description">
        {{ programa?.horario }}
      </dd>
    </dl>

    <dl
      class="
        programa__general-info__detail programa__general-info__detail--modalidad
      "
    >
      <dt class="programa__general-info__detail__title">Modalidad</dt>
      <dd class="programa__general-info__detail__description">
        {{ programa?.modalidad }}
      </dd>
    </dl>

    <dl
      class="
        programa__general-info__detail programa__general-info__detail--brochure
      "
    >
      <dt class="programa__general-info__detail__title">Brochure</dt>
      <dd class="programa__general-info__detail__description">
        <a [href]="programa?.brochure" target="_blank">Descargar</a>
      </dd>
    </dl>

    
    <dl
      class="
        programa__general-info__detail programa__general-info__detail--contacto
      "
    >
      <dt class="programa__general-info__detail__title">Solicitar información</dt>
      <dd class="programa__general-info__detail__description">
        <a [routerLink]="['/contacto']" class="actions__button rounded-lg" nzType="primary" nz-button>Aquí</a>
      </dd>
    </dl>
  </div>
</div>
