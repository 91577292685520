import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { pluck, tap } from 'rxjs';

import { ElementoMenu, Pagina } from '@core/models';
import { PaginaHttpService } from '@core/http';

@Component({
  selector: 'espg-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
})
export class ContentPage implements OnInit {
  public pageData: Pagina | undefined;
  public title: string;
  public description: string;
  public keywords: string;
  public enlaces: ElementoMenu[];

  constructor(private route: ActivatedRoute,private titleService: Title, private metaService: Meta,  private readonly paginaService: PaginaHttpService ) {
    this.title = "Escuela de PostGrado UPT";
    this.description = "Página principal de la Escuela de PostGrado de la Universidad Privada de Tacna";
    this.keywords = "UPT, PostGrado, Universidad";

    this.enlaces = [];
  }

  ngOnInit(): void {
   this.getDataFromResolver();
  }

  private getDataFromResolver() {
    this.route.data
      .pipe(
        pluck('pageData'),
        tap((data: Pagina) => {
          this.pageData = data;
          this.getEnlaces(data.id);
        })
      )
      .subscribe(()=>{
        this.title = this.pageData?.titulo.toString() || "";
        this.titleService.setTitle(this.title);
      } );

    this.metaService.addTags([
      {name: 'description', content: this.route.snapshot.data["pageData"]["descripcion"] },
      {name: 'keywords', content: this.route.snapshot.data["pageData"]["palabras_clave"] },
      // {name: 'robots', content: 'index, follow'}
    ]);
  }

  private async getEnlaces(paginaId: number) {
    this.paginaService
      .getMenu(paginaId)
      .then((response) => (this.enlaces = response));
  }
}
