import { Component, OnInit } from '@angular/core';
import { ProgramaHttpService } from '@core/http';
import { Programa } from '@core/models';

@Component({
  selector: 'espg-programas-convocados-layout',
  templateUrl: './programas-convocados-layout.component.html',
  styleUrls: ['./programas-convocados-layout.component.scss']
})
export class ProgramasConvocadosLayoutComponent implements OnInit {

  public maestrias: Programa[] = [];
  public doctorados: Programa[] = [];

  constructor(
    private readonly programaHttpService: ProgramaHttpService
  ) {}

  ngOnInit(): void {
    this.getProgramas();
  }

  private getProgramas() {
    this.programaHttpService
      .getTipoPrograma('maestrias', 'programas')
      .then((tipoPrograma) => {
        this.maestrias = tipoPrograma.programas.filter(function(element){
          return element.en_convocatoria;
        }).map((element) => {
          element.imagen = element.imagen?.length > 10 ? element.imagen : ''; 
          return element
        });
      });

      
    this.programaHttpService
    .getTipoPrograma('doctorados', 'programas')
    .then((tipoPrograma) => {
      this.doctorados = tipoPrograma.programas.filter(function(element){
        return element.en_convocatoria;
      }).map((element) => {
        element.imagen = element.imagen?.length > 10 ? element.imagen : ''; 
        return element
      });
    });
  }

  public recotrarDenominacion (tipo: string, cadena: string): string{
    switch (tipo) {
      case "doctorado":
        cadena = cadena.replace("Doctorado en",""); 
        break;
      case "maestria":
        cadena = cadena.replace("Maestría en","");
        break;
      default:
        break;
    }
    return cadena;
  }
}
