import { Component, OnInit } from '@angular/core';

import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { ContactoHttpService, NoticiaHttpService, ProgramaHttpService, SliderHttpService } from '@core/http';
import { ElementoSlider, Noticia, Programa } from '@core/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SendMailContactoFormRequest } from '@core/form-requests';
import Swal from 'sweetalert2';


SwiperCore.use([Navigation, Autoplay]);

@Component({
  selector: 'espg-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  public contactForm!: FormGroup;
  public imagen:string = "";
  public noticias!: Noticia[];
  public elementosSlider!: ElementoSlider[];
  public programas: Programa[] | undefined;
  isVisible = false;
  isConfirmLoading = false;

  constructor(
    private readonly sliderHttpService: SliderHttpService,
    private readonly programaHttpService: ProgramaHttpService,
    private readonly contactoHttpService: ContactoHttpService,  
    private readonly noticiasHttpService: NoticiaHttpService
  ) {
    this.sliderHttpService.getImagenesSlider().then((result) => {
      this.elementosSlider = result;
      this.imagen = this.elementosSlider[0].imagen_movil
    });
   this.noticiasHttpService.getNoticiasDetacadas().then((result) => {
      this.noticias = result

      if (this.noticias.length){
        this.showModal();

      }
    });
    this.getProgramas();
  }

  selectValidator(control: FormControl): { [key: string]: boolean } | null {
    return control.value === 'S' ? { 'required': true } : null;
  }
  
  showModal(): void {
    this.isVisible = true;
  }


  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 1000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }
   
  private getProgramas() {
    this.programaHttpService
      .getAllProgramas(null, true, null)
      .then((data) => {
        return this.programas = data.filter(function(element){
          return element.en_convocatoria == true;
        });
      });
  }


}
